import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { ALERT_MSG_ERROR, AUTH_TOKEN_KEY, translate, URL_CREATELIST_OF_PARTNER_USER, URL_GETALL_PARTNER_SUPER, URL_GET_USER_FULL } from "../utils"
import ModalAlert from "../components/modalAlert"
import DeleteButtonImg from "../images/plus.svg"
import AddButton from "../images/min.svg"
import axios from "axios"
const Preferences = () => {
  const [showAlert, setShowAlert] = useState(false)
  const [listOfUserPartner, setListOfUserPartner] = useState([])
  const [listOfPartnerAvailable, setListOfPartnerAvailable] = useState([])
  const [arrPartners, setArrPartners] = useState([])
  const [alertTitle, setAlertTitle] = useState("")
  const [alertMessage, setAlertMessage] = useState("")

  const getUser =async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const url = URL_GET_USER_FULL
   await axios
      .get(url, data)
      .then(res => {
        if (res.data) {
         
          const { listOfInterests, listOfPartners } = res.data 
          const names=listOfPartners.map(item=>item.name)
          setListOfUserPartner([...names])
       
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }
  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  useEffect(() => {
if(listOfUserPartner.length<1)
    getUser()
    getListOfPartnerSuperUnchoiced()

    x(true)
  }, [])

  const getListOfPartnerSuperUnchoiced = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL_PARTNER_SUPER, data)
      .then(res => {
        if (res.data) {
         
          const partnerAvailable = []
          res.data.forEach(item => partnerAvailable.push(item.name))
          const partnerAvailableFiltered = partnerAvailable.filter(
            item => !listOfUserPartner.includes(item)
          )
      
        setListOfPartnerAvailable([...partnerAvailableFiltered.map(item =>({
            name: item,
            isAdded: false,
          }))])
      
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else console.log(err)
        console.log("error during get user")
      })

         
  }


 const addPartner = async (itemToAdd,index) => {
    const userPartner = [...listOfUserPartner];
    let listOfPartnerAvailable = [...listOfPartnerAvailable];
    userPartner.push(itemToAdd.name);
    listOfPartnerAvailable[index].isAdded = true
  setListOfUserPartner(userPartner)
    setListOfPartnerAvailable(listOfPartnerAvailable)
    x(false)
  }


  const x =async(i)=>{     
       //short arrInterest
      
    console.clear();
    await sleep(3180)
    console.log("hey",listOfPartnerAvailable);
    console.log("--------------")
    setArrPartners([])
    //  const resList=listOfUserPartner.map((el,index)=>({id:(i===true)?index:el.id,name:el,isAdded:true}))
     setArrPartners(...listOfUserPartner.map((el,index)=>({id:(i===true)?index:el.id,name:el,isAdded:true})));
     setListOfPartnerAvailable(...listOfPartnerAvailable.map((el,index)=>({id:(i===true)?index:el.id,name:el,isAdded:false})))
    // listOfUserPartner.forEach((element,index) => 
    //   this.state.arrPartners.push({id:(i===true)?index:element.id,name:element,isAdded:true})
    // );
    // this.state.listOfPartnerAvailable.forEach((element,index) => 
    //   this.state.arrPartners.push({id:(i===true)?index:element.id,name:element.name,isAdded:false})
    // );
  setArrPartners(arrPartners.sort(compare))
    // this.state.arrPartners= this.state.arrPartners.sort(this.compare);

    const filteredArr = arrPartners.reduce((acc, current) => {
    const x = acc.find(item => item.name === current.name);
    if (!x) {
      return acc.concat([current]);
      } else {
      return acc;
      }
    }, [])

    setArrPartners(filteredArr);
    console.log(arrPartners);
    // fin short arrInterest
    return 'ok'
  
  
  }


 const pressSave = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const header = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const data = {
      listOfPartnerSuperName: this.state.listOfUserPartner
    }
   await axios.post(URL_CREATELIST_OF_PARTNER_USER,data,header)
      .then(res => {
        console.log('res')
        if (res.data) {
          console.log('res.data',res.data)
        }
        window.location.reload();
      })
      .catch(err => {
        if (err.response && err.response.data) {
          console.log(err.response.data);
          this.setState({
            showAlert: true,
            alertTitle: ALERT_MSG_ERROR,
            alertMessage: err.response.data.message,
          })
        }
        console.log(err)
      })
  }
 const compare=(a, b)=> {
    
    // Use toUpperCase() to ignore character casing
    const bandA = a.name.toUpperCase();
    const bandB = b.name.toUpperCase();
  
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

 const deletePartner = async (itemToDelete,index) => {
    const userPartner = [...listOfUserPartner];
    let listOfPartnerAvailable = [...listOfPartnerAvailable];

    userPartner.splice(index,1)

    listOfPartnerAvailable.push({
      name: itemToDelete,
      isAdded: false
    })
    setListOfUserPartner(userPartner)
    setListOfPartnerAvailable(listOfPartnerAvailable)
      x(false)
  }



  return (
    <Layout>
      
       
    <div >
     
      
     <h1>{translate("Partenaires")}</h1>
//     <h5>{translate('send form')}</h5>
     <div className={'modal-user__contain scroll-wrapper'} >
     <p><strong>{translate('Modal Préférence partenaires')}</strong></p>
     {arrPartners.map((item,index) => {
         if (!item.isAdded) { return  <div className={"modal-user__contain__item modal-user__contain__item__new"}>
           <div className={'modal-user__contain__item__container'}>
           <img onClick={() => this.addPartner(item,item.id)} src={AddButton}/> {item.name}
           </div>
         </div>
         } else{
           return  <div key={index} className={"modal-user__contain__item modal-user__contain__item__owned "}>
           <div className={'modal-user__contain__item__container'}>
           <img onClick={() => this.deletePartner(item.name,item.name)} src={DeleteButtonImg}/> {item.name}
             <div id="permission-yellow-ahmad" className="permission-yellow-ahmad">
             <p>
             { translate("Ja, ik ga ermee akkoord dat mijn gegevens kunnen worden doorgegeven aan deze enter-business partner. Hierdoor kunnen zij contact met mij opnemen of mij staaltjes, kortingen, aanbiedingen en informatie sturen in overeenstemming met ons privacybeleid")}
            {(item.name=="Proximus")?
             translate("condition Proximus")
             :null
             }
          </p> </div>
           </div>
         </div> 
         }
         }
       )}
  

     
     </div>
     <div className={'modal-user__btn-container'}>
       <a onClick={() =>this.pressSave()} className={'modal-user__btn'}>
         {translate('Opslaan')}
       </a>
     </div>
     {(showAlert)?
       <ModalAlert
         alertTitle={alertTitle}
         alertMessage={alertMessage}
         closeModal={() => setShowAlert(false)}
       />:null
     }
   </div>
       
    </Layout>
  )
}

export default Preferences

import React, { Component } from "react"
import DeleteButtonImg from '../images/plus.svg';
import AddButton from '../images/min.svg';
import Close from "../images/close.svg"
import {
  translate,
} from "../utils"
import axios from "axios"

class ModalAlert extends Component {
  state = {

  }

  componentDidMount = async () => {

  }
  render() {
    const {alertMessage,alertTitle} = this.props
    return (
      <div className={'modal-alert'} style={{position: 'fixed',top:'50%',left: '50%',transform: 'translate(-50%, -50%)',zIndex: '9999999999'}}>
        {(alertMessage.isArray)?
          alertMessage.map(item => <p>{translate(item)}.</p>)
          :<p>{translate((alertMessage)?alertMessage:'')}.</p>
        }
        <div className={'modal-alert__btn-container'}>
          <a onClick={this.props.closeModal} className={'modal-alert__btn'}>
            {translate('OK')}
          </a>
        </div>
      </div>
    )
  }
}

export default ModalAlert;